import React from 'react' 
import LoginBox from './Sections/LoginBox'

export default function SignIn() {
    return (
        <>

            <LoginBox buttonTitle="Sign in" innerTitle={'Create an account'} />

        </>
    )
}
