import React from 'react'
import LoginBox from './Sections/LoginBox'


export default function SignUp() {
  return (
    <>

            <LoginBox Access={'SignUp'}  buttonTitle="Sign Up" innerTitle={'Sign in'}/>

        </>
  )
}
